import classNames from 'classnames';
import * as React from 'react';
import './styles/Alert.scss';

type Props = React.PropsWithChildren & {
  type: 'warning' | 'error' | 'info';
};

const Alert: React.FC<Props> = (props) => {
  return <div className={classNames('alert', `alert-${props.type}`)}>{props.children}</div>;
};

export default Alert;
