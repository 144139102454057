import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { path, sortBy } from '@src/shared/src/util/general';
import { useTranslation } from 'react-i18next';

// Constants
import { DATE_FORMAT_TYPES, ENVIRONMENT } from '@src/shared/src/const/app';

// Components
import { TransportationBookingInfo } from '@src/components/TransportationBookingInfo';
import CheckoutSeatMapLink from './CheckoutSeatMapLink';

// Styles
import '../styles/BookingInfo.scss';

// Models
import {
  BookingItemModel,
  BookingHotelFareModel,
  BookingTransportFareModel,
  UserModel,
} from '@src/shared/src/models';
import BookingRentalFareModel from '@src/shared/src/models/BookingRentalFareModel';

// Selectors
import { selectors } from '@src/shared/src';
import { IRootState } from '@src/store';
import { CheckoutPrice } from '.';
import { formatDate, formatDayDifference } from '@src/shared/src/util/date';
import { HotelIcon } from '@toolkit/ui/icons';
import { BookingInfoItem } from '@src/components/BookingInfoItem';
import { StationIcon } from '@toolkit/ui/icons/StationIcon';
import { CarTag } from '@toolkit/ui/icons/CarTag';
import { searchSelectors } from '@src/shared/src/selectors';

type Props = {
  bookingItems: BookingItemModel[];
  env: ENVIRONMENT;
  seatMapSelectorUrl?: string;
  showSelectionWarning?: boolean;
  showTransportTitle?: boolean;
};

const BookingInfo: React.FC<Props> = ({
  bookingItems,
  env,
  seatMapSelectorUrl,
  showSelectionWarning,
  showTransportTitle,
}) => {
  const { t } = useTranslation();
  const bookingHasAirbnb: boolean = useSelector((state: IRootState) =>
    selectors.checkout.isAnyBookingItemAirbnb(state),
  );
  const profile: UserModel = useSelector((state: IRootState) => state.adminUser.profile);
  const search = useSelector((state: IRootState) => searchSelectors.search(state.search));
  
  const getHotelBookingTitle = () => {
    if (bookingHasAirbnb) {
      return t('global.airbnb');
    }
    return t('global.hotel');
  };

  const getHotelDateInfo = () => {
    const nights = formatDayDifference(search.depAt, search.arrAt)
    return `${formatDate(search.depAt, DATE_FORMAT_TYPES.DAY_LONG_DATE)} | ${nights} ${nights > 1 ? t('global.nights') : t('global.night')}`;
  }
  const getHotelBookingMarkup = (fare: BookingHotelFareModel) => (
    <BookingInfoItem
      title={showTransportTitle ? getHotelBookingTitle() : ''}
      icon={<HotelIcon />}
      depName={fare.hotel.name}
      depAt={getHotelDateInfo()}
      key={`fare-hotel-${fare.id}`}
    />
  );

  const getRentalBookingMarkup = (fare: BookingRentalFareModel) => (
    <BookingInfoItem
      title={showTransportTitle ? t('BookingInfo.booking.subTitle.rental') : ''}
      icon={<StationIcon />}
      depName={`${t('BookingInfo.booking.rental.pickup')} \u30FB ${search.depName}`}
      arrName={`${t('BookingInfo.booking.rental.return')} \u30FB ${search.depName}`}
      depAt={formatDate(search.depAt, DATE_FORMAT_TYPES.LONG_DATE_TIME)}
      arrAt={formatDate(search.arrAt, DATE_FORMAT_TYPES.LONG_DATE_TIME)}
      key={`fare-rental-${fare.id}`}>
      <>
        <div className="lp-booking-info-item-sub_title">
          <CarTag />
          {`${fare.vehicleExamples[0]} ${t('rental.titel.similar')}`}
        </div>
        <span className="lp-booking-info-item-label">{t('BookingItem.label.carType')}</span>
      </>
    </BookingInfoItem>
  );

  const hotelBookings = bookingItems.filter((item) => item.fareType === 'hotel_fare');
  const transportBookings = bookingItems.filter((item) => item.fareType === 'transport_fare');
  const rentalBookings = bookingItems.filter((item) => item.fareType === 'rental_fare');

  return (
    <div className="lp-booking-info">
      <div className="lp-booking-info-title">
        {t('BookingInfo.title.bookingInformation')}
        <CheckoutSeatMapLink
          seatMapSelectorUrl={seatMapSelectorUrl}
          showSelectionWarning={showSelectionWarning}
        />
      </div>
      <div className="lp-booking-info-items">
        {transportBookings.map((bookingItem) => {
          const transportFares = sortBy(path(['segment', 'depAt']))(bookingItem.transportFares);
          return (
            <div key={`bookingItem-${bookingItem.id}`} className="lp-booking-info-section">
              {transportFares.map((fare: BookingTransportFareModel) => (
                <TransportationBookingInfo
                  key={fare.id}
                  fare={fare}
                  env={env}
                  bookingItemSeatReservationInfo={bookingItem.seatReservation}
                  profile={profile}
                  showTitle={showTransportTitle}
                />
              ))}
              <CheckoutPrice
                price={bookingItem.totalPrice}
                label={t('BookingInfo.label.subTotal')}
              />
            </div>
          );
        })}
        {rentalBookings.map((bookingItem) => (
          <div key={`bookingItem-${bookingItem.id}`} className="lp-booking-info-section">
            {bookingItem.rentalFares.map(getRentalBookingMarkup)}
            <CheckoutPrice price={bookingItem.totalPrice} label={t('BookingInfo.label.subTotal')} />
          </div>
        ))}
        {hotelBookings.map((bookingItem) => (
          <div key={`bookingItem-${bookingItem.id}`} className="lp-booking-info-section">
            {bookingItem.hotelFares.map(getHotelBookingMarkup)}
            <CheckoutPrice price={bookingItem.totalPrice} label={t('BookingInfo.label.subTotal')} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingInfo;
