import { DATE_FORMAT_TYPES } from '../const/app';
import DateFormatter from './formatters/DateFormatter';
import { format } from 'date-fns';
import { differenceInDays, startOfDay } from 'date-fns';

export const formatDate = (date: Date | number, fmtType: DATE_FORMAT_TYPES = DATE_FORMAT_TYPES.SHORT_DATE) => {
  return DateFormatter.getInstance().getFormatter(fmtType).format(date);
};

export const formatDayDifference = (date:Date, refDate:Date) => {
  return differenceInDays(startOfDay(refDate), startOfDay(date));
};

export const formatDateRange = (
  fromDate: Date | number,
  toDate: Date | number,
  separator: string = '-',
  fmtType: DATE_FORMAT_TYPES = DATE_FORMAT_TYPES.SHORT_DATE) =>
  `${formatDate(fromDate, fmtType)} ${separator} ${formatDate(toDate, fmtType)}`

export const formatOnlyDate = (date: string) => {
  const [year, month, day] = date.substr(0, 10).split('-');
  return format(new Date(
    Number(year),
    (Number(month) - 1),
    Number(day),
  ), 'yyyy-MM-dd')
}
