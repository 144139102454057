import * as React from 'react';
import TextField from './TextField';

export default (props) => {
  return (
    <TextField
      name={props.input.name}
      onBlur={props.input.onBlur}
      onChange={props.input.onChange}
      onFocus={props.input.onFocus}
      value={props.input.value}
      label={props.placeholder}
      errorMsg={props.meta.error}
    />
  );
};
