import * as React from 'react';

// Utils
import { useTranslation } from 'react-i18next';
import { Navbar } from '@toolkit/ui';
import { SearchModel } from '@src/shared/src/models';
import { DATE_FORMAT_TYPES, STATUS } from '@src/shared/src/const/app';
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
import { navigate, isCurrentPath } from '@toolkit/util/app';
import { ROUTES } from '@toolkit/const/app';

// Constants
// Actions
// Models
// Interfaces
// Components
// Styles

type Props = {
  basketStatus: string;
  search: SearchModel;
  isBasketEditable: boolean;
};

const CheckoutMobileNavbar: React.FC<Props> = ({ basketStatus, search, isBasketEditable }) => {
  const { t } = useTranslation();

  if (basketStatus !== STATUS.CONFIRM_FINISHED && basketStatus !== STATUS.CONFIRM_FAILED && basketStatus !== STATUS.CONFIRM_PENDING) {
    return (
      <Navbar
        showBackButton={isBasketEditable || isCurrentPath(ROUTES.BOOKING.PAYMENT)}
        iconRight={'icon-edit'}
        actionRight={() => navigate('/')}
        theme="checkout">
        <div>
          {t('checkoutConn.navbar.title')}
          <div className="lp-navbar-subtitle">
            {search.arrAt
              ? formatDateRange(search.depAt, search.arrAt, '-', DATE_FORMAT_TYPES.DAY_SHORT_DATE)
              : formatDate(search.depAt, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}
          </div>
        </div>
      </Navbar>
    );
  }

  return null;
};

export default CheckoutMobileNavbar;
