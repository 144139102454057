import classNames from 'classnames';
import * as React from 'react';

// Utils
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles
import './styles/BookingInfoItem.scss';

type Props = React.PropsWithChildren & {
  title: string;
  icon: JSX.Element;
  depName: string;
  depAt: string;
  arrName?: string;
  arrAt?: string;
};

export const BookingInfoItem: React.FC<Props> = (props) => {
  const { title, icon, depName, arrName, depAt, arrAt, children } = props;

  return (
    <div className="lp-booking-info-item">
      {title && <div className="lp-booking-info-item-title">{title}</div>}
      <div className="lp-booking-info-item-inner">
        <div className="lp-booking-info-item-details">
          <div
            className={classNames('lp-booking-info-item-route', {
              'is--dep': arrName && arrAt,
            })}>
            {icon}
            <div className="lp-booking-info-item-location">{depName}</div>
            <div className="lp-booking-info-item-date">{depAt}</div>
          </div>
          {arrName && arrAt && (
            <div className="lp-booking-info-item-route is--arr">
              <div className="lp-booking-info-item-location">{arrName}</div>
              <div className="lp-booking-info-item-date">{arrAt}</div>
            </div>
          )}
        </div>
        {children && <div className="lp-booking-info-item-additional">{children}</div>}
      </div>
    </div>
  );
};
