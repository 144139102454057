import * as React from 'react';

// Utils
import { getUserFullname } from '@src/shared/src/util/users';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { PassengerModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles

type Props = {
  passengers: PassengerModel[];
};

const CheckoutPaymentPassengersInfo: React.FC<Props> = (props: Props) => (
  <div className="checkout-box box">
    <h2 className="checkout-title">{t('checkoutPaymentInfo.title.travellers')}</h2>
    <div className="checkout-box-content">
      <div className="checkout-passengers">
        {props.passengers.map((passenger, idx) => (
          <div key={`passenger-${passenger.id}-${idx}`} className="checkout-passengers-item">
            <i className="material-symbols">account_circle</i>
            <div>
              <label>
                {t('checkoutPaymentInfo.label.traveler')} {idx + 1}
              </label>
              <strong>{getUserFullname(passenger)}</strong>
            </div>
          </div>
        ))}
      </div>
      <p className="checkout-passengers-note">
        {t('checkoutPaymentInfo.text.description.travellersNames')}
      </p>
    </div>
  </div>
);

export default CheckoutPaymentPassengersInfo;
