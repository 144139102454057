import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { CheckoutPrice } from '.';
import '../styles/CheckoutBookingInfo.scss';

type Props = {
  costCenter: string;
  costUnit: string;
  referenceCode: string;
  remarks: string;
  totalPrice: number;
};

export const CheckoutFooterConfirm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const checkoutFooterContent = [];
  props.costCenter &&
    checkoutFooterContent.push(
      <div key="costCenter">
        {t('CheckoutFooter.label.costCenter')} <strong>{props.costCenter}</strong>
      </div>,
    );
  props.costUnit &&
    checkoutFooterContent.push(
      <div key="costUnit">
        {t('CheckoutFooter.label.costUnit')} <strong>{props.costUnit}</strong>
      </div>,
    );
  props.referenceCode &&
    checkoutFooterContent.push(
      <div key="referenceCode">
        {t('CheckoutFooter.label.referenceCode')} <strong>{props.referenceCode}</strong>
      </div>,
    );
  props.remarks &&
    checkoutFooterContent.push(
      <div key="remarks">
        {t('checkout-footer.label.remarks')} <strong>{props.remarks}</strong>
      </div>,
    );

  return (
    <>
      <CheckoutPrice
        type="total"
        price={props.totalPrice}
        label={t('CheckoutFooter.label.totalBookable')}>
        {checkoutFooterContent.length !== 0 && (
          <div className="checkout-booking-info">{checkoutFooterContent}</div>
        )}
      </CheckoutPrice>
    </>
  );
};
