import * as React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

// Utils
import { required } from '@toolkit/util/formsValidation';
import { t } from '@toolkit/util/i18n';
import {
  composeValidators,
  checkSelectValueUniqueness,
  checkSelectPercentage,
  checkRequiredCostCenter,
  checkNullCostCenter,
} from '@toolkit/util/formsValidation';
// Constants
import { COST_CENTER_CATEGORIES } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
import { Button, Link, SelectWithPercentage } from '@toolkit/ui';
// Styles
import '../styles/MultiCostCentersFormField.scss';
import '../styles/FormGroup.scss';

type Props = {
  required: boolean;
  userIds: number[];
  displayAllUrl: string;
  formFieldName: string;
  category: COST_CENTER_CATEGORIES;
};
export const MultiCostCentersFormField: React.FC<Props> = (props) => {
  const validators = [checkSelectPercentage, checkSelectValueUniqueness, checkNullCostCenter];

  if (props.required) {
    validators.push(checkRequiredCostCenter);
  }

  const getCategoryLabel = () => {
    return props.category === COST_CENTER_CATEGORIES.COST_CENTER
      ? t('checkoutPaymentAccountingForm.label.costCenter')
      : t('checkoutPaymentAccountingForm.label.costUnit');
  };

  return (
    <div className="checkout-form">
      <label htmlFor="costCenter" className="checkout-form-label">
        {getCategoryLabel()}
        <div className="checkout-form-flex checkout-form-supporting">
          {t('costCentersForm.label.costCenterAllocationMessage')}
          {!(window as any).__IS_WEBVIEW && (
            <>
              &nbsp;|&nbsp;
              <Link href={props.displayAllUrl} targetBlank={true} type="small">
                {props.category === COST_CENTER_CATEGORIES.COST_CENTER
                  ? t('checkoutPaymentAccountingForm.link.all_cost_centers')
                  : t('checkoutPaymentAccountingForm.link.all_cost_units')}
              </Link>
            </>
          )}
        </div>
      </label>

      <div className="multi-cost-centers-form-fields">
        <FieldArray name={props.formFieldName} validate={composeValidators(...validators)}>
          {({ fields, meta }) => {
            if (fields.length < 1 && props.required) {
              fields.push({
                selectValue: undefined,
                selectLabel: undefined,
                percentageValue: 100,
              });
            }

            return (
              <div>
                {fields.map((costCenter, index) => {
                  // Generate a unique key for each cost center field
                  // React won't update the remaining fields correctly after a field is removed from the middle of the fields list when using keys based on index
                  const costCenterKey = `${index}-${fields.value[index].percentageValue}-${fields.value[index].selectValue}`;

                  return (
                    <div key={costCenterKey} className="multi-cost-centers-form-fields-row">
                      <div className="multi-cost-centers-form-fields-select">
                        <Field
                          name={costCenter}
                          clearable={false}
                          backspaceRemoves={false}
                          deleteRemoves={false}
                          validate={props.required ? required : undefined}
                          required={props.required}
                          userIds={props.userIds}
                          category={props.category}
                          component={SelectWithPercentage}
                        />
                      </div>

                      {(fields.length > 1 || (fields.length === 1 && !props.required)) && (
                        <button
                          className="multi-cost-centers-form-fields-delete"
                          type="button"
                          onClick={() => fields.remove(index)}>
                          <i className="material-symbols">delete</i>
                        </button>
                      )}
                    </div>
                  );
                })}

                {meta.error && <div className="form-error-message">{meta.error}</div>}
                {fields.length > 1 && (
                  <div className="tcp-form-group-notice">
                    {t('costCentersForm.label.costCenterRemoveMessage')}
                  </div>
                )}
                {fields.length < 5 && (
                  <Button
                    className="button button-light"
                    type="button"
                    onClick={() =>
                      fields.push({
                        selectValue: undefined,
                        selectLabel: undefined,
                        percentageValue: 100,
                      })
                    }>
                    <i className="material-symbols is--first">add_circle</i>
                    {`${getCategoryLabel()} ${t('costCentersForm.button.add')}`}
                  </Button>
                )}
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
