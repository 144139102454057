import * as React from 'react';

// Utils
// Constants
// Actions, Models & Interfaces
// Components
import classNames from 'classnames';
// Styles
import './styles/Spinner.scss';

type Props = {
  type?: 'sm' | 'md' | 'lg';
  fill?: 'grey' | 'black'
};

const Spinner: React.FC<Props> = (props) => (
  <div className={classNames('tcp-spinner', { 
    [`size--${props.type}`]: props.type,
    [`fill--${props.fill}`]: props.fill 
  })}>
    <svg viewBox="22 22 44 44" className="tcp-spinner-svg">
      <circle
        className="tcp-spinner-circle"
        cx="44"
        cy="44"
        r="20.2"
        fill="none"
        strokeWidth="3.6"></circle>
    </svg>
  </div>
);

export default Spinner;
