import * as React from 'react';
import { connect } from 'react-redux';
import { differenceInMinutes } from 'date-fns';

// Utils
import {
  errorLogger,
} from '@src/shared/src/util/errors';
import {
  getMainLegs,
  getTripLastTS,
  isLegTariffNil,
  findBySelected,
} from '@src/shared/src/util/trips';
import {
  cond,
  path,
  any,
  map,
  last,
  head,
  equals,
  T,
 } from '@src/shared/src/util/general';
import { notify, baseErrorCond } from '@toolkit/util/app';
import { formatDate } from '@src/shared/src/util/date';
import { t } from '@toolkit/util/i18n';

// Constants
import { ENVIRONMENT, NOTIF_TYPE, DATE_FORMAT_TYPES, DIRECTION } from '@src/shared/src/const/app';
// Actions
import { changeShuttleTimeAsync } from '@src/shared/src/actions/tripsActions';
import { checkout as checkoutSelectors } from '@src/shared/src/selectors';
// Models
import {
  TripModel,
  LegModel,
  TariffModel,
  TravelBookingFareExtraModel
} from '@src/shared/src/models';
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import Leg from './Leg';
import {
  TripPrice,
  TripTooltip,
} from '@pod/trips/components';

import { Button } from '@toolkit/ui';
// Styles
import '../styles/TripDetails.scss';
import { PinIcon } from '@toolkit/ui/icons';

type Props = ConnectedRedux<IRootState> & {
  env:ENVIRONMENT;
  trip:TripModel;
  searchDate:Date;
  onCloseDetails: () => void;
  getTariffStatus?:(tariffId:number) => string;
  getTariffLuggage?:(tariffId:number) => TravelBookingFareExtraModel;
  onSelectTrip:(tripId:number, tariffIds:number[], direction:DIRECTION) => void;
  isSelectingTrip?:boolean;
  isRoundtrip:boolean;
  uiTripChangingTariffsloader:boolean;
  isBasketEditable?:boolean;
  direction: DIRECTION;
};

class TripDetail extends React.Component<Props> {
  private getTWTime = (startLeg:LegModel, endLeg:LegModel) => {
    const startDate = last(startLeg.legOption.transportSegments).arrAt;
    const endDate = head(endLeg.legOption.transportSegments).depAt;
    return differenceInMinutes(endDate, startDate);
  }

  private onChangeShuttleTime = (legOptionId:number, timing:string) => {
    this.props.dispatch(
      changeShuttleTimeAsync.request({
        onError: cond(baseErrorCond),
        legOptionId,
        timing,
        tripId: this.props.trip.id,
      })
    );
  }

  public getLegMarkup = (tripLegs:LegModel[] = []) => {
    return tripLegs.map((leg) =>
      <div key={leg.id}>
        <Leg
          leg={leg}
          tripId={this.props.trip.id}
          searchDate={this.props.searchDate}
          env={this.props.env}
          expanded={this.props.trip.expanded}
          getTariffStatus={this.props.getTariffStatus}
          getTariffLuggage={this.props.getTariffLuggage}
          isBasketEditable={this.props.isBasketEditable}
          onChangeShuttleTime={this.onChangeShuttleTime}
          isLastLeg={tripLegs.indexOf(leg) === (tripLegs.length - 1)}
          waitingTime={tripLegs.indexOf(leg) !== tripLegs.length - 1 ?
            this.getTWTime(leg, tripLegs[tripLegs.indexOf(leg) + 1]) : null }
        />
      </div>
    );
  }


  private getSelectedTariffs = (trip: TripModel) => {
    const selectedTariffs = map(
      (leg:LegModel) => {
        const selectedTariff:TariffModel = findBySelected(leg.legOption.tariffs);
        if (selectedTariff) {
          return selectedTariff.id;
        } else {
          // If we are here something went wrong
          errorLogger(
            `TripId:${trip.id} :: LegId:${leg.id}`,
            new Error('Could not find a selected tariff for leg')
          );
          notify('Could not select this tariff, please start your search again', NOTIF_TYPE.ERROR);
          throw new Error('Could not find a selected tariff for leg');
        }
      }
      , trip.legs);
    return selectedTariffs;
  }

  private getSelectMarkup = (trip:TripModel) => {
    if (this.props.env === ENVIRONMENT.SEARCH && this.props.isBasketEditable) {
      return (
          <div className="tcp-trip-details-footer">
            <div className="tcp-trip-details-footer-price">
              <TripPrice tripBaseTotal={trip.baseTotal} env={this.props.env}/>
              {this.props.isRoundtrip && <div><TripTooltip/></div>}
            </div>
            <Button
              isLoading={
                any(isLegTariffNil, trip.legs) ||
                this.props.isSelectingTrip ||
                this.props.uiTripChangingTariffsloader
              }
              className="button is--medium"
              onClick={() => this.selectTrip(trip.id, trip)}>

              {t('global.select')}
              <i className="icon-arrow_forward"></i>
            </Button>
          </div>
      );
    }
  }

  private selectTrip = (tripId:number, trip:TripModel) => {
    this.props.onSelectTrip(tripId, this.getSelectedTariffs(trip), this.props.direction);
    this.props.onCloseDetails();
  }


  public render() {
    const { trip, env} = this.props;

    const tripLegs = cond([
      [equals(ENVIRONMENT.SEARCH), () => trip.legs],
      [equals(ENVIRONMENT.JOURNEY), () => trip.legs],
      [T, () => getMainLegs(trip.legs)],
    ])(env);

    return (
      <div className="tcp-trip-details">
        <div className="tcp-trip-details-inner">
          {this.getLegMarkup(tripLegs)}
          <div className="tcp-leg tcp-leg-inbound">
            <div className="tcp-leg-time">
              {formatDate(path(['arrAt'], getTripLastTS(tripLegs)), DATE_FORMAT_TYPES.SHORT_TIME)}
            </div>
            <div className="tcp-leg-item tcp-leg-item-inbound">
              <PinIcon className="tcp-trip-routes-icon-arr" />
              <div className="tcp-leg-location">{path(['arrName'], getTripLastTS(tripLegs))}</div>
            </div>
          </div>
          {this.getSelectMarkup(trip)}
        </div>
      </div>
    );
  }
}
const mapState = (state:IRootState) => ({
  uiTripChangingTariffsloader: state.ui.uiTripChangingTariffsloader,
  isBasketEditable: checkoutSelectors.isBasketEditable(state.checkout),
});
export default connect(mapState)(TripDetail);
