import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { isAnyBookingItemAirbnb } from '@src/shared/src/selectors/checkoutSelectors';
// Constants
import { STATUS } from '@src/shared/src/const/app';
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models & Interfaces
// Components
import { Button } from '@toolkit/ui';
import { CalendarIcon, TicketIcon } from '@toolkit/ui/icons';
// Styles
import '../styles/CheckoutSidebarConfirmBody.scss';

type Props = {
  basketStatus: STATUS;
  bookingId: string;
};

export const CheckoutSidebarConfirmBody: React.FC<Props> = ({ basketStatus, bookingId }) => {
  const bookingHasAirbnb = useSelector(isAnyBookingItemAirbnb);
  const renderICal = () => {
    if ((window as any).__IS_WEBVIEW) {
      return (
        <p>
          <strong>{t('checkoutSideBarConfirmationInfo.text.ios.addToCalendar')}</strong>
        </p>
      );
    } else {
      return (
        <>
          <Button
            className="button button-light"
            onClick={() =>
              window.location.assign(`${API_URL.ORG_ADMIN_TRAVEL_BOOKINGS}/${bookingId}/ics`)
            }>
            <CalendarIcon />
            {t('checkoutSideBarConfirmationInfo.button.addToCalendar')}
          </Button>
        </>
      );
    }
  };

  return (
    <div className="sidebar-inner">
      <div className="sidebar-section-inner checkout-sidebar-confirm-body">
        <div className="checkout-sidebar-confirm-body-title">
          {t('checkoutSideBarConfirmationInfo.title.prepareTrip')}
        </div>

        {bookingHasAirbnb
          ? t('checkout-sidebar-confirm-body.text.description-start-airbnb')
          : t('checkout-sidebar-confirm-body.text.description-start')}
        <p>{t('checkout-sidebar-confirm-body.text.description-calendar')}</p>
        {basketStatus === STATUS.CONFIRM_FINISHED && renderICal()}
        <p>{t('checkout-sidebar-confirm-body.text.description')}</p>
        <Button
          className="button button-light"
          onClick={() => window.location.assign(`${API_URL.DEFAULT}/org_admin/travel/bookings`)}>
          <TicketIcon />
          {t('checkoutSideBarConfirmationInfo.button.seeAllBookings')}
        </Button>
        <p>{t('checkout-sidebar-confirm-body.text.description-tickets')}</p>
      </div>
    </div>
  );
};
