import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import {
  any as ramdaAny,
  flatten,
  formatCents,
  isNil,
  isNilOrEmpty,
  isNotNilOrEmpty,
  map,
  pipe,
  pluck,
  propEq,
  propOr,
} from '@src/shared/src/util/general';
import { notify } from '@toolkit/util/app';
// Constants
import { NOTIF_TYPE, SEAT_RES_STATUS, VEHICLE_TYPES } from '@src/shared/src/const/app';
// Actions
// Models & Interfaces
import { BookingTransportFareModel, SelectedSeatModel } from '@src/shared/src/models';
import { SeatAssignmentsInfo } from './SeatAssignmentsInfo';
// Components
// Styles

type Props = {
  fare: BookingTransportFareModel;
  bookingItemSeatReservationInfo?: string;
};

const getSelectedSeats = (fare: BookingTransportFareModel): SelectedSeatModel[] =>
  pipe(map(propOr([], 'selectedSeats')), flatten)(fare.segment.seatMaps);

const hasSeatReservation = (fare: BookingTransportFareModel) => {
  if (fare.segment.vehicles.includes(VEHICLE_TYPES.PLANE)) {
    return isNotNilOrEmpty(getSelectedSeats(fare));
  } else {
    return (
      fare.seatReservation === SEAT_RES_STATUS.AVAILABLE ||
      fare.seatReservation === SEAT_RES_STATUS.INCLUDED
    );
  }
};

const getSeatReservationPrice = (fare: BookingTransportFareModel) => {
  if (fare.segment.vehicles.includes(VEHICLE_TYPES.PLANE)) {
    return getSelectedSeats(fare).reduce((sum, selectedSeat) => sum + selectedSeat.price, 0);
  } else if (fare.seatReservation === SEAT_RES_STATUS.AVAILABLE) {
    return fare.seatReservationPrice;
  }
};

const hasDiscountedSeat = (fare: BookingTransportFareModel) =>
  ramdaAny(propEq('discounted', true))(getSelectedSeats(fare));

const getSeatReservationDetails = (fare: BookingTransportFareModel) => {
  const selectedSeats = getSelectedSeats(fare);
  if (isNotNilOrEmpty(selectedSeats)) {
    return (
      <span className="lp-booking-info-item-label">
        {t('BookingItem.label.seat')}&nbsp;
        {selectedSeats.map((selectedSeat) => selectedSeat.display).join(', ')}
      </span>
    );
  }
};

const hasSeatAssignmentsInfo = (fare: BookingTransportFareModel) =>
  pipe(pluck('seatAssignments'), flatten, isNotNilOrEmpty)(fare.segment.segments);

export const TransportationBookingSeatInfo: React.FC<Props> = ({
  fare,
  bookingItemSeatReservationInfo,
}) => {
  const seatReservationPrice = getSeatReservationPrice(fare);

  if (hasDiscountedSeat(fare)) {
    notify(t('transportationBookingInfo.seat.discounted.message'), NOTIF_TYPE.SUCCESS);
  }

  if (hasSeatReservation(fare)) {
    return (
      <div>
        <div className="lp-booking-info-item-sub_title">
          <i className="icon-seating" />
          {t('BookingItem.label.seatReservation')}:{' '}
          {!isNil(seatReservationPrice)
            ? ` +${formatCents(seatReservationPrice)}`
            : ` ${t('tariffFareTerms.label.seatReservation.included')}`}
        </div>
        {getSeatReservationDetails(fare)}

        {hasSeatAssignmentsInfo(fare) && (
          <SeatAssignmentsInfo
            segmentVehicles={fare.segment.vehicles}
            subSegments={fare.segment.segments}
          />
        )}

        {!isNilOrEmpty(bookingItemSeatReservationInfo) && (
          <div>{bookingItemSeatReservationInfo}</div>
        )}
      </div>
    );
  }

  return null;
};
