import * as React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

// Utils
import { partial } from '@src/shared/src/util/general';
import { maxLen, required, composeValidators } from '@toolkit/util/formsValidation';
import { t } from '@toolkit/util/i18n';
// Constants
import { COST_CENTER_CATEGORIES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { IRootState } from '@src/store';
import { AccountingInvoiceProfileModel } from '@src/shared/src/models';
// Components
import { SelectInput, FormInput } from '@src/toolkit/ui';
import { CostCentersFormField } from '.';
// Styles
import '../styles/FormGroup.scss';

type Props = {
  costCenterRequired: boolean;
  costUnitRequired: boolean;
  purposeOfTripRequired: boolean;
  referenceCodeRequired: boolean;
  accountingInvoiceProfiles: AccountingInvoiceProfileModel[];
};
const maxLenValidate = partial(maxLen, [50]);

const CheckoutPaymentAccountingForm: React.FC<Props> = (props: Props) => {
  const organization = useSelector((state: IRootState) => state.organization.org);

  return (
    <div className="checkout-box checkout-payment box">
      <h2 className="checkout-title">{t('checkoutPaymentInfo.label.accounting')}</h2>
      {props.accountingInvoiceProfiles.length > 1 && (
        <div className="checkout-form">
          <label htmlFor="accountingInvoiceProfile" className="checkout-form-label">
            {t('checkoutPaymentAccountingForm.label.accountingInvoiceProfile')}
          </label>
          <Field
            name="accountingInvoiceProfile"
            options={props.accountingInvoiceProfiles.map((aip) => ({
              label: aip.name,
              value: aip.id,
            }))}
            validate={required}
            parse={(val) => val?.value}
            component={SelectInput}
          />
        </div>
      )}
      <CostCentersFormField
        category={COST_CENTER_CATEGORIES.COST_CENTER}
        formFieldName="costCenters"
        organizationId={organization.id}
        multiValuesEnabled={organization.multiCostCentersEnabled}
        valueRequired={props.costCenterRequired}
      />
      {organization.costUnitsEnabled && (
        <CostCentersFormField
          category={COST_CENTER_CATEGORIES.COST_UNIT}
          formFieldName="costUnits"
          organizationId={organization.id}
          multiValuesEnabled={organization.multiCostUnitsEnabled}
          valueRequired={props.costUnitRequired}
        />
      )}
      <div className="checkout-form">
        <div className="checkout-form-row">
          <Field
            name="referenceCode"
            placeholder={t('checkoutPaymentAccountingForm.label.referenceCode')}
            component={FormInput}
            validate={
              props.referenceCodeRequired
                ? composeValidators(maxLenValidate, required)
                : maxLenValidate
            }
          />
          <Field
            name="purposeOfTrip"
            placeholder={t('checkoutPaymentAccountingForm.label.purposeOfTrip')}
            type="textarea"
            component={FormInput}
            validate={props.purposeOfTripRequired ? required : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckoutPaymentAccountingForm;
