import * as React from 'react';

// Utils
import useBreakpoints from '@toolkit/util/useBreakpoints';
import { errorLogger } from '@src/shared/src/util/errors';
import { findBySelected, verifyProperty, isNil } from '@src/shared/src/util/general';
// import useBreakpoints from '@toolkit/util/useBreakpoints';
import { useSelector } from 'react-redux';

// Constants
import { ENVIRONMENT, ERROR_SEVERITY } from '@src/shared/src/const/app';
// Actions
import { selectors } from '@src/shared/src';
// Models
import { RentalFareModel, RentalModel } from '@src/shared/src/models';
import { IRootState } from '@src/store';
// Components
import RentalTitle from './RentalTitle';
import RentalPickUpInfo from './RentalPickUpInfo';
import RentalDriversInfo from './RentalDriversInfo';
import RentalCarDetails from './RentalCarDetails';
import RentalCarExtras from './RentalCarExtras';
// Styles
import '../styles/Rental.scss';
import BookedRentalItemInfo from './BookedRentalItemInfo';
import RentalRate from './RentalRate';

type Props = {
  rental: RentalModel;
  env: ENVIRONMENT;
  detailsOpened?: boolean;
};

const Rental: React.FC<Props> = ({ rental, env }) => {
  const { isMd } = useBreakpoints();
  const search = useSelector((state: IRootState) => selectors.search.search(state.search));
  const passengers = useSelector((state: IRootState) =>
    selectors.search.searchPassengers(state.search),
  );

  const selectedRentalFare: RentalFareModel = findBySelected(rental.fares);

  if (isNil(selectedRentalFare)) {
    errorLogger(
      'Rental.tsx',
      new Error('Could not find any selected rental fare'),
      ERROR_SEVERITY.ERROR,
    );
  }

  const rentalImage = () => (
    <div className="rental-image">
      <img src={selectedRentalFare.carImageUrl} />
    </div>
  );

  return (
    <div className="rental">
      {isMd && (
        <RentalTitle
          vehicleExample={verifyProperty('', selectedRentalFare, ['vehicleExamples', '0'])}
          vehicleGroup={rental.vehicle.group}
        />
      )}
      <div className="rental-inner">
        {!isMd && rentalImage()}

        <div className="rental-info">
          {!isMd && (
            <RentalTitle
              vehicleExample={verifyProperty('', selectedRentalFare, ['vehicleExamples', '0'])}
              vehicleGroup={rental.vehicle.group}
            />
          )}
          <div className="rental-details-wrapper">
            <RentalCarDetails rentalVehicle={rental.vehicle} />
            {isMd && rentalImage()}
          </div>
          <RentalDriversInfo passengers={passengers} />
          <RentalRate fare={selectedRentalFare} />
          <RentalCarExtras rentalExtras={findBySelected(rental.fares).extras} />
        </div>
      </div>
      <RentalPickUpInfo search={search} rental={rental} />
      <BookedRentalItemInfo rental={rental} env={env} />
    </div>
  );
};

export default Rental;
