import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/CheckoutSeatMapLink.scss';
import Alert from '@toolkit/ui/Alert';

type Props = {
  seatMapSelectorUrl: string;
  showSelectionWarning: boolean;
};

const CheckoutSeatMapLink: React.FC<Props> = ({ seatMapSelectorUrl, showSelectionWarning }) => {
  if (!isNil(seatMapSelectorUrl)) {
    return (
      <>
        <a className="lp-checkout-button-link" href={seatMapSelectorUrl}>
          <span className="button">
            <i className="icon-seating is--first" />
            {t('checkoutPrepareConn.button.selectSeat')}
          </span>
        </a>
        {showSelectionWarning && (
          <Alert type="warning">
            {t('redeemedSearchNotification.warning.seatReservationWarning')}
          </Alert>
        )}
      </>
    );
  }
  return null;
};

export default CheckoutSeatMapLink;
