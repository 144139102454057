import classnames from 'classnames';
import * as React from 'react';
import './styles/Link.scss';

type Props = {
  children: React.ReactNode;
  icon?: JSX.Element;
  onClick?: any;
  href?: string;
  targetBlank?: boolean;
  type?: 'small' | 'medium';
  disabled?: boolean;
  className?: string;
};

const Link: React.FC<Props> = (props) => {
  const classes = classnames(
    'tcp-link',
    props.className,
    { [`is--${props.type}`]: props.type },
    {
      'is--disabled': props.disabled,
    },
  );

  if (props.href) {
    return (
      <a className={classes} href={props.href} target={props.targetBlank ? '_blank' : '_self'}>
        {props.icon}
        {props.children}
      </a>
    );
  }
  return (
    <button className={classes} onClick={props.onClick} disabled={props.disabled}>
      {props.icon}
      {props.children}
    </button>
  );
};

export default Link;
