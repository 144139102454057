import * as React from 'react';

// Utils
import { useTranslation } from 'react-i18next';
// Constants
// Actions
// Models
import { RentalModel } from '@src/shared/src/models';
// Interfaces
// Components
import CheckoutSideBarRentalInfoItem from './CheckoutSideBarRentalInfoItem';
// Styles

type Props = {
  rentals: RentalModel[];
};

const CheckoutSideBarRentalInfo: React.FC<Props> = (props: Props) => {
  const { rentals } = props;
  const { t } = useTranslation();
  const aggregatorIdent = rentals[0].rentalSearch.aggregator.effectiveIdent;
  return (
    <>
      <CheckoutSideBarRentalInfoItem
        aggregatorIdent={aggregatorIdent}
        title={t('CheckoutSideBar.titel.rentalPickUp')}
        route={rentals[0].rentalSearch.depStationName}
      />
      <CheckoutSideBarRentalInfoItem
        aggregatorIdent={aggregatorIdent}
        title={t('CheckoutSideBar.titel.rentalReturn')}
        route={rentals[0].rentalSearch.arrStationName}
      />
    </>
  );
};

export default CheckoutSideBarRentalInfo;
