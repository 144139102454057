import * as React from 'react';

// Utils
import { getOperatorImageDetailURL } from '@toolkit/util/app';
// Constants
import { VEHICLE_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
// Styles

type Props = {
  aggregatorIdent: string;
  title: string;
  route: string;
};

const CheckoutSideBarRentalInfoItem: React.FC<Props> = (props: Props) => {
  const { aggregatorIdent, title, route } = props;

  return (
    <div className="sidebar-inner">
      <div className="sidebar-title">{title}</div>

      <div className="sidebar-route">
        <img src={getOperatorImageDetailURL(aggregatorIdent, VEHICLE_TYPES.RENTAL)} />
        <div className="sidebar-route-title">{route}</div>
      </div>
    </div>
  );
};

export default CheckoutSideBarRentalInfoItem;
