import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@toolkit/ui';
import { PinRemoveIcon } from '@toolkit/ui/icons/PinRemove';
import { useDispatch, useSelector } from 'react-redux';
import { searchActions } from '@src/shared/src/actions';
import { SearchSectionModel } from '@src/shared/src/models';
import { IRootState } from '@src/store';
import { searchSelectors } from '@src/shared/src/selectors';
import { SEARCH_TYPE } from '@src/shared/src/const/app';
import { isMultiCitySearch } from '@src/shared/src/util/search';
import { PinAddIcon } from '@toolkit/ui/icons/PinAddIcon';

const ToggleRouteButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const search = useSelector((state: IRootState) => searchSelectors.search(state.search));

  const onAddAdditionalRoute = () => {
    dispatch(searchActions.setSearchType(SEARCH_TYPE.MULTICITY));
    dispatch(
      searchActions.setSearchSections([
        search.sections[0],
        new SearchSectionModel({
          depLat: search.sections[0].arrLat,
          depLng: search.sections[0].arrLng,
          depName: search.sections[0].arrName,
          depLocType: search.sections[0].arrLocType,
          depAt: search.sections[1]?.depAt, // keep selected date if exists
        }),
      ]),
    );
  };

  const onRemoveAdditionalRoute = () => {
    dispatch(searchActions.setSearchType(SEARCH_TYPE.ALL));
    dispatch(
      searchActions.setSearchSections([
        search.sections[0],
        new SearchSectionModel({
          depLat: search.sections[0].arrLat,
          depLng: search.sections[0].arrLng,
          depName: search.sections[0].arrName,
          depLocType: search.sections[0].arrLocType,
          arrLat: search.sections[0].depLat,
          arrLng: search.sections[0].depLng,
          arrName: search.sections[0].depName,
          arrLocType: search.sections[0].depLocType,
          depAt: search.sections[1]?.depAt, // keep selected date if exists
        }),
      ]),
    );
  };

  if (isMultiCitySearch(search)) {
    return (
      <Link className="search-bar-route-link" type='medium' onClick={onRemoveAdditionalRoute}>
        <PinRemoveIcon />
        {t('searchBarConn.button.remove_section')}
      </Link>
    );
  }

  return (
    <Link className="search-bar-route-link" type='medium' onClick={onAddAdditionalRoute}>
      <PinAddIcon />
      {t('searchBarConn.button.add_section')}
    </Link>
  );
};

export default ToggleRouteButton;
