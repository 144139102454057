import * as React from 'react';
import * as sanitizeHTML from 'sanitize-html';

// Utils
import {
  head,
  isNil,
  isNilOrEmpty,
  map,
  partial,
  partialRight,
} from '@src/shared/src/util/general';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
import { highlightKeyword, parseOptionToSearchNodeModel } from '@toolkit/util/app';
import i18n from '@toolkit/util/i18n';
// Constants
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models
import { OptionModel, SearchNodeModel } from '@src/shared/src/models';
import {
  AppartmentIcon,
  HomeIcon,
  PinDropIcon,
  PinIcon,
  StarIcon,
  StarsIcon,
  TransitIcon,
  WorkIcon,
} from '@toolkit/ui/icons';
// Interfaces
// Components
import { Label, Link, MenuListDevider } from '@toolkit/ui';
// Styles
import '../styles/SearchBarRouteSelector.scss';
import '../styles/ResultsList.scss';
import { LOCATION_TYPES } from '@toolkit/const/app';

type Props = AppContextProp & {
  suggestions: SearchNodeModel[];
  homeLocations: OptionModel;
  workLocations: OptionModel;
  favoriteLocations: OptionModel[];
  onSuggestionSelected: (suggestion: SearchNodeModel) => void;
  searchStr: string;
  profileId: number;
};

class SearchBarRouteSelector extends React.PureComponent<Props> {
  private getLocationIconByType = (types: string[]) => {
    const type = head(types);
    switch (type) {
      case LOCATION_TYPES.ESTABLISHMENT:
        return <AppartmentIcon />;
      case LOCATION_TYPES.TRANSIT_STATION:
        return <TransitIcon />;
      case LOCATION_TYPES.LODGING:
        return <AppartmentIcon />;
      case LOCATION_TYPES.LOCALITY || LOCATION_TYPES.SUBLOCALITY:
        return <PinDropIcon />;
      default:
        return <PinIcon />;
    }
  };

  private getSuggestionMarkup = (suggestion: SearchNodeModel, icon?: JSX.Element) => {
    if (isNil(suggestion) || isNil(this.props.searchStr)) {
      return null;
    }
    return (
      <li key={suggestion.reference}>
        <button
          title={suggestion.name}
          id={String(suggestion.reference)}
          onClick={partial(this.props.onSuggestionSelected, [suggestion])}
          className="results-list-item">
          {icon ? icon : this.getLocationIconByType(suggestion.types)}
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeHTML(highlightKeyword(suggestion.name, this.props.searchStr)),
            }}
          />
        </button>
      </li>
    );
  };

  private getUserAddressesMarkup = (
    homeAddress: OptionModel,
    workAddress: OptionModel,
    favoriteList: OptionModel[],
  ) => {
    if (!isNil(homeAddress) || !isNil(workAddress) || !isNilOrEmpty(favoriteList)) {
      return (
        <>
          <Label>{i18n.t('searchBarRouteSelector.label.favorites')}</Label>
          <ul className="results-list">
            {homeAddress &&
              this.getSuggestionMarkup(parseOptionToSearchNodeModel(homeAddress), <HomeIcon />)}
            {workAddress &&
              this.getSuggestionMarkup(parseOptionToSearchNodeModel(workAddress), <WorkIcon />)}
            {!isNilOrEmpty(favoriteList) &&
              map(
                partialRight(this.getSuggestionMarkup, [<StarIcon />]),
                map(parseOptionToSearchNodeModel, favoriteList),
              )}
          </ul>
        </>
      );
    } else if (this.props.appContext.isMediaBPNotMobile) {
      return (
        <Link
          icon={<StarsIcon />}
          href={`${API_URL.DEFAULT}/org_admin/users/${this.props.profileId}/user_preference/edit`}>
          {i18n.t('searchBarRoute.link.addFavLocations')}
        </Link>
      );
    }
  };

  public render() {
    const { suggestions, homeLocations, workLocations, favoriteLocations } = this.props;
    return (
      <>
        {!isNilOrEmpty(suggestions) && (
          <>
            <ul className="results-list">{map(this.getSuggestionMarkup, suggestions)}</ul>
            <MenuListDevider />
          </>
        )}
        {this.getUserAddressesMarkup(homeLocations, workLocations, favoriteLocations)}
      </>
    );
  }
}

export default withAppContext(SearchBarRouteSelector);
