import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { Link } from '@toolkit/ui';
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles

type Props = {
  navToPrepare: () => void;
};

const CheckoutHeaderPayment: React.FC<Props> = (props) => {
  return (
    <Link onClick={props.navToPrepare}>
      <i className="icon-arrow_back" />
      {t('CheckoutHeader.button.backToOverview')}
    </Link>
  );
};

export default CheckoutHeaderPayment;
