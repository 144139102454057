import classNames from 'classnames';
import * as React from 'react';
import { FieldProps } from 'redux-form';
import ErrorMessageTooltip from './ErrorMessageTooltip';

type Props = FieldProps & {
  id: string;
  label: string;
  icon?: string;
  outerCSS?: string;
  wrapperCSS?: string;
  labelCSS?: string;
  errorMessage?: string;
};

const FormCheckbox: React.FC<Props> = (props: Props) => {
  const ref = React.useRef(null);

  const executeScroll = () => ref.current.scrollIntoView({ behavior: 'smooth' });

  React.useEffect(() => {
    if (props.meta.touched && props.meta.error) {
      executeScroll();
    }
  }, [props.meta.touched, props.meta.error]);

  return (
    <div className={props.outerCSS} ref={ref}>
      <div className={props.wrapperCSS}>
        <div
          className={classNames('fac fac-checkbox-o fac-default', {
            'has--error': props.meta.touched && props.meta.error,
          })}>
          <span />
          <input {...props.input} id={props.id} type="checkbox" />
          <label className={props.labelCSS} htmlFor={props.id}>
            {props.icon && <i className={props.icon} />}
            {props.label}
          </label>
        </div>
        <ErrorMessageTooltip
          displayMessage={props.meta.touched && props.meta.error}
          errorMessage={props.meta.error}
        />
      </div>
    </div>
  );
};

export default FormCheckbox;
