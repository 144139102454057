import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { formatDate } from '@src/shared/src/util/date';
import {
  minutesToHoursAndMinutes,
  pipe,
  map,
  path,
  concat,
  flatten,
  uniq,
} from '@src/shared/src/util/general';
// Constants
import {
  ENVIRONMENT,
  VEHICLE_TYPES,
  SEAT_RES_STATUS,
  TRAIN_OPERATORS,
  DATE_FORMAT_TYPES,
} from '@src/shared/src/const/app';
// Actions
// Models
import { BookingTransportFareModel, UserModel } from '@src/shared/src/models';
// Interfaces
// Components
import { TransportationBookingSeatInfo } from './TransportationBookingSeatInfo';
import { Link, Tooltip } from '@toolkit/ui';
import { getVehicleIcon } from '@toolkit/util/app';
import { BookingInfoItem } from './BookingInfoItem';
// Styles

type Props = {
  fare: BookingTransportFareModel;
  bookingItemSeatReservationInfo: string;
  env: ENVIRONMENT;
  profile: UserModel;
  showTitle?: boolean;
};

const getTransportTitle = (fare: BookingTransportFareModel, translateFunc: any) =>
  pipe(
    map(
      pipe(
        path(['segment', 'vehicles']),
        map(concat('BookingInfo.booking.subTitle.')),
        map(translateFunc),
      ),
    ),
    flatten,
    uniq,
  )([fare]);

const getDbahnChangeSeatReservationMarkup = (
  fare: BookingTransportFareModel,
  profile: UserModel,
  env: ENVIRONMENT,
) => {
  if (
    env === ENVIRONMENT.CONFIRMATION &&
    fare.segment.operators.includes(TRAIN_OPERATORS.DEUTSCHEBAHN) &&
    (fare.seatReservation === SEAT_RES_STATUS.INCLUDED ||
      fare.seatReservation === SEAT_RES_STATUS.AVAILABLE)
  ) {
    return (
      <div>
        <div className="lp-booking-info-item-sub_title">
          <b>{t('TransportationBookingInfo.label.change_seat_res')}</b>
        </div>
        <div>
          <Link
            href={t('TransportationBookingInfo.link.change_seat_res_info', {
              email: profile.email,
            })}
            targetBlank={true}>
            {t('TransportationBookingInfo.link.change_seat_res')}
            <i className="icon-arrow_forward" />
          </Link>
        </div>
      </div>
    );
  }
};

const getPlaneAdditionalServicesMarkup = (
  fare: BookingTransportFareModel,
  profile: UserModel,
  env: ENVIRONMENT,
) => {
  if (env === ENVIRONMENT.CONFIRMATION && fare.segment.vehicles.includes(VEHICLE_TYPES.PLANE)) {
    return (
      <div>
        <Link
          disabled={profile.organization.isDemo}
          href={t('TransportationBookingInfo.link.services', { email: profile.email })}
          targetBlank={true}>
          {t('TransportationBookingInfo.label.services')}
          <i className="icon-arrow_forward" />
        </Link>

        {profile.organization.isDemo && (
          <Tooltip
            icon="lp-icon-terms lp-icon-terms-more-default"
            bodyTextFirst={t('TransportationBookingInfo.demoDisabledSupportTooltip.text')}
          />
        )}
      </div>
    );
  }
};

export const TransportationBookingInfo: React.FC<Props> = (props) => {
  const { bookingItemSeatReservationInfo, fare, env, profile, showTitle } = props;

  return (
    <BookingInfoItem
      title={showTitle ? getTransportTitle(fare, t) : ''}
      icon={<i className={`icon ${getVehicleIcon(fare.segment.vehicles[0] as VEHICLE_TYPES)}`} />}
      depName={fare.segment.depName}
      depAt={`${formatDate(fare.segment.depAt, DATE_FORMAT_TYPES.LONG_DATE_TIME)}`}
      arrName={fare.segment.arrName}
      arrAt={`${minutesToHoursAndMinutes(fare.segment.duration)} | ${formatDate(
        fare.segment.arrAt,
        DATE_FORMAT_TYPES.LONG_DATE_TIME,
      )}`}
      key={`fare-transport-${fare.id}`}>
      <TransportationBookingSeatInfo
        fare={fare}
        bookingItemSeatReservationInfo={bookingItemSeatReservationInfo}
      />
      {getDbahnChangeSeatReservationMarkup(fare, profile, env)}
      {getPlaneAdditionalServicesMarkup(fare, profile, env)}
    </BookingInfoItem>
  );
};
