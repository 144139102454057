import * as React from 'react';
import Spinner from './Spinner';
import './styles/LabelButton.scss';

type Props = {
  children:any;
  className?:string;
  icon?:string;
  disabled?:boolean;
  isLoading?:boolean;
  onClick: () => void;
};

const LabelButton: React.FC<Props> = (props) => {
  return (
    <button
      className={`tcp-label-button ${props.className}`}
      disabled={props.disabled || props.isLoading}
      onClick={props.onClick}>

      {props.icon && !props.isLoading &&
        <i className={props.icon}/>
      }
      {props.isLoading &&
        <Spinner type="sm" fill='black'/>
      }
      <span>{props.children}</span>
    </button>
  );
};


export default LabelButton;
