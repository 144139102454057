// Utils
import {
  find,
  verifyProperty,
} from './general';
// Constants
// Actions, Models & Interfaces
import {
  UserModel,
  SettingsModel,
  PassengerModel,
} from '../models';

export const getUserFullname = (user:UserModel | PassengerModel) =>
  `${verifyProperty('', user, ['firstName'])} ${verifyProperty('', user, ['lastName'])}`;

export const getMilesAndMoreStatusByCardIdent = (value: string) => {
  switch (value.substr(0, 4)) {
    case '9922':
      return 'loyaltyCard.milesAndMode.status.frequentTraveller';
    case '2220':
      return 'loyaltyCard.milesAndMode.status.senator';
    case '3330':
      return 'loyaltyCard.milesAndMode.status.honCircle';
    default :
      return 'loyaltyCard.milesAndMode.status.none';
  }
};

export const getLCCardNameFromIdent = (cardIdent: string, loyaltyCardsArray: SettingsModel[]) =>
  find((lc: SettingsModel) => lc.ident === cardIdent, loyaltyCardsArray).name;
