import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { minutesToHoursAndMinutes, path, equals, T, cond } from '@src/shared/src/util/general';
import {
  getLegsChangeOvers,
  getLegsDuration,
  getMainLegs,
  getTripFirstTS,
  getTripLastTS,
} from '@src/shared/src/util/trips';
import { getTripOperatorImageURL } from '@toolkit/util/app';
import { formatDate } from '@src/shared/src/util/date';
import i18n from '@toolkit/util/i18n';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
// Constants
import { ENVIRONMENT, STATUS, DATE_FORMAT_TYPES, DIRECTION } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { TravelBookingFareExtraModel, TripModel } from '@src/shared/src/models';
import { setUiMobileNoScrolling } from '@pod/ui/actions';
import { IRootState, ConnectedRedux } from '@src/store';
// Components
import {
  TripDetails,
  TripPrice,
  TripRoutes,
  TripTimeInfo,
  TripTooltip,
} from '@pod/trips/components';
import { BookedItemsInfo } from '@src/pod/checkout/components';
import { Spinner, Navbar } from '@toolkit/ui';
// Styles
import '../styles/Trip.scss';
import classNames from 'classnames';

type Props = ConnectedRedux<IRootState> &
  AppContextProp & {
    env: ENVIRONMENT;
    trip: TripModel;
    searchDate?: Date;
    detailsOpened?: boolean;
    getTariffStatus?: (tariffId: number) => string;
    getTariffLuggage?: (tariffId: number) => TravelBookingFareExtraModel;
    onFetchDetail: (tripId: number) => void;
    onCloseDetail: (tripId: number) => void;
    onSelectTrip: (tripId: number, tariffIds: number[], direction: DIRECTION) => void;
    isRoundtrip: boolean;
    isLoading?: boolean;
    isSelectingTrip?: boolean;
    direction: DIRECTION;
  };
type State = {
  showDetails: boolean;
};

class Trip extends React.PureComponent<Props, State> {
  readonly state: State = {
    showDetails: this.props.detailsOpened || false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.detailsOpened !== this.props.detailsOpened) {
      this.setState({ showDetails: this.props.detailsOpened });
    }
  }

  static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps.trip.status === STATUS.FAILED) {
      return {
        showDetails: false,
      };
    }

    return null;
  }

  private openMobileDetails = () => {
    const { isLoading, trip, appContext } = this.props;

    if (
      trip.status !== STATUS.FAILED &&
      !isLoading &&
      !appContext.isMediaBPNotMobile &&
      !this.state.showDetails
    ) {
      this.toggleDetails();
    }
  };

  private toggleDetails = () => {
    if (!this.state.showDetails) {
      this.props.onFetchDetail(this.props.trip.id);
      if (!this.props.appContext.isMediaBPNotMobile) {
        this.props.dispatch(setUiMobileNoScrolling(true));
      }
    } else {
      this.props.dispatch(setUiMobileNoScrolling(false));
      this.props.onCloseDetail(this.props.trip.id);
    }
    this.setState({ showDetails: !this.state.showDetails });
  };

  private closeDetails = () => {
    this.props.dispatch(setUiMobileNoScrolling(false));
    this.props.onCloseDetail(this.props.trip.id);
    this.setState({ showDetails: false });
  };

  private getTripSelectionMarkup = () => {
    const { isLoading, trip, env, isRoundtrip, appContext } = this.props;
    if (isLoading) {
      return (
        <div className="tcp-trip-select">
          <Spinner />
        </div>
      );
    }

    if (trip.status === STATUS.FAILED) {
      return (
        <div className="tcp-trip-select tcp-trip-select-not-available">
          <i className="icon-disabled" />
          {i18n.t('trip.text.unavailable')}
        </div>
      );
    }

    return (
      <div className="tcp-trip-select">
        {env === ENVIRONMENT.SEARCH && (
          <span>
            <div className="tcp-trip-price">
              <TripPrice tripBaseTotal={trip.baseTotal} env={env} />
            </div>
            {isRoundtrip && <TripTooltip />}
          </span>
        )}
        {appContext.isMediaBPNotMobile && (
          <button
            className={classNames('button', {'button-light': this.state.showDetails || env === ENVIRONMENT.CHECKOUT || env === ENVIRONMENT.CONFIRMATION })}
            onClick={this.toggleDetails}>
            {this.state.showDetails
              ? i18n.t('global.close_details')
              : i18n.t('global.view_details')}
          </button>
        )}
      </div>
    );
  };

  public render() {
    const { trip, env, appContext } = this.props;

    // Only display main/bookable legs in checkout
    const tripMainLegs = getMainLegs(trip.legs);
    const tripLegs = cond([
      [equals(ENVIRONMENT.SEARCH), () => trip.legs],
      [equals(ENVIRONMENT.JOURNEY), () => trip.legs],
      [T, () => tripMainLegs],
    ])(env);
    const tripDuration = cond([
      [equals(ENVIRONMENT.SEARCH), () => trip.duration],
      [equals(ENVIRONMENT.JOURNEY), () => trip.duration],
      [T, () => getLegsDuration(tripMainLegs)],
    ])(env);
    const tripChangeOvers = cond([
      [equals(ENVIRONMENT.SEARCH), () => trip.changeovers],
      [equals(ENVIRONMENT.JOURNEY), () => trip.changeovers],
      [T, () => getLegsChangeOvers(tripMainLegs)],
    ])(env);
    const tripFirstTS = getTripFirstTS(tripLegs);
    const tripLastTS = getTripLastTS(tripLegs);
    return (
      <div className={classNames('tcp-trip', { 'tcp-trip-is-open-details': this.state.showDetails })}>
        {!appContext.isMediaBPNotMobile && this.state.showDetails && (
          <Navbar actionRight={() => this.toggleDetails()} iconRight="icon-close2">
            {i18n.t('trip.button.details')}
          </Navbar>
        )}
        <div className="tcp-trip-inner" onClick={() => this.openMobileDetails()}>
          <div className="tcp-trip-top">
            <div className="tcp-trip-provider">
              <img src={getTripOperatorImageURL(trip)} />
            </div>
            <div className="tcp-trip-infos">
              <TripRoutes
                searchDate={this.props.searchDate}
                tripArrAt={path(['arrAt'], tripLastTS)}
                tripDepAt={path(['depAt'], tripFirstTS)}
                tripLegs={tripLegs}
                tripDuration={tripDuration}
              />
              <div className="tcp-trip-location">
                <span>{path(['depName'], tripFirstTS)}</span>
                <span>{path(['arrName'], tripLastTS)}</span>
              </div>
            </div>
          </div>
          <div className="tcp-trip-footer">
            <div className="tcp-trip-time">
              <TripTimeInfo
                tripDuration={minutesToHoursAndMinutes(tripDuration)}
                tripTime={formatDate(
                  path(['depAt'], tripFirstTS),
                  DATE_FORMAT_TYPES.DAY_SHORT_DATE,
                )}
                tripChangeOvers={tripChangeOvers}
                env={env}
              />
            </div>
            {this.getTripSelectionMarkup()}
          </div>
          {!appContext.isMediaBPNotMobile && (
            <div className="tcp-trip-indicator">
              <i className="icon-keyboard_arrow_down" />
            </div>
          )}
        </div>
        {this.state.showDetails && (
          <TripDetails
            direction={this.props.direction}
            getTariffStatus={this.props.getTariffStatus}
            getTariffLuggage={this.props.getTariffLuggage}
            trip={this.props.trip}
            env={this.props.env}
            searchDate={this.props.searchDate}
            isSelectingTrip={this.props.isSelectingTrip}
            isRoundtrip={this.props.isRoundtrip}
            onSelectTrip={this.props.onSelectTrip}
            onCloseDetails={this.closeDetails}
          />
        )}
        <BookedItemsInfo trip={trip} env={env} />
      </div>
    );
  }
}
export default connect()(withAppContext(Trip));
