import * as React from 'react';

// Utils
import { formatCents } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/CheckoutPrice.scss';
import classNames from 'classnames';

type Props = React.PropsWithChildren & {
  label: string;
  price: number;
  type?: 'subtotal' | 'total';
};

const CheckoutPrice: React.FC<Props> = (props) => (
  <div
    className={classNames(
      'lp-checkout-price',
      { 'is--total': props.type === 'total' },
      { 'is--subtotal': props.type === 'subtotal' },
    )}>
    <div className="lp-checkout-price-inner">
      <div className="lp-checkout-price-label">{props.label}</div>
      <div className="lp-checkout-price-number">{formatCents(props.price)}</div>
    </div>
    {props.children}
  </div>
);

export default CheckoutPrice;
