import * as React from 'react';

import { navigate, navigateExternally, isCurrentPath } from '@toolkit/util/app';
// Constants
import { ROUTES } from '@toolkit/const/app';
import { API_URL } from '@src/shared/src/const/api';
// Models
// Interfaces
import { IRootState } from '@src/store';
// Actions
import { selectors } from '@src/shared/src';
import { useSelector } from 'react-redux';
import {
  isHotelOnlySearch,
  isOnewaySearch,
  isRentalOnlySearch,
  shouldSearchForHotels,
} from '@src/shared/src/util/search';
// Styles
require('./styles/Navbar.scss');

type Props = React.PropsWithChildren<{
  showBackButton?: boolean;
  iconRight?: string;
  labelRight?: string;
  actionRight?: any;
  theme?: string;
}>;

const Navbar: React.FC<Props> = ({
  showBackButton,
  iconRight,
  labelRight,
  actionRight,
  theme,
  children,
}) => {
  const search = useSelector((state: IRootState) => state.search.currentSearch);
  const bookingRental = useSelector((state: IRootState) =>
    selectors.checkout.rental(state.checkout),
  );
  const getBackRoute = () => {
    if (isCurrentPath(ROUTES.BOOKING.PAYMENT)) {
      return `${ROUTES.BOOKING.PREPARE}${search.id}`;
    }

    if (isCurrentPath(ROUTES.TRIPS.INBOUND)) return ROUTES.TRIPS.OUTWARD + search.id;

    if (isCurrentPath(ROUTES.HOTELS)) {
      if (isHotelOnlySearch(search)) return ROUTES.HOME;
      return ROUTES.TRIPS.INBOUND + search.id;
    }

    if (isCurrentPath('/booking/')) {
      if (isOnewaySearch(search)) return ROUTES.TRIPS.OUTWARD + search.id;
      if (shouldSearchForHotels(search)) return ROUTES.HOTELS + search.id;
      return ROUTES.TRIPS.INBOUND + search.id;
    }

    return ROUTES.HOME;
  };

  const navigateBack = () => {
    if (isRentalOnlySearch(search) && !isCurrentPath(ROUTES.BOOKING.PAYMENT)) {
      navigateExternally(API_URL.RENTAL_SEARCH + bookingRental.id);
    } else {
      navigate(getBackRoute());
    }
  };

  return (
    <div className={`lp-navbar ${theme ? 'lp-navbar-' + theme : ''}`}>
      {showBackButton && (
        <button onClick={navigateBack} className="lp-navbar-button lp-navbar-button-left">
          <i className="icon-arrow_back" />
        </button>
      )}

      {children}

      {actionRight && (
        <button onClick={actionRight} className="lp-navbar-button lp-navbar-button-right">
          {labelRight && <div className="lp-navbar-button-label">{labelRight}</div>}
          <i className={iconRight} />
        </button>
      )}
    </div>
  );
};

export default Navbar;
